<template>
  <base-section id="legal" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row>
        <v-col cols="12">
          <v-card color="#D1D9F1" tile flat>
            <base-loading v-if="loading" />

            <template v-else>
              <v-card-title>{{ $t("legalConcernTitle") }}</v-card-title>
              <v-card-text>
                <i18n
                  path="legalConcern"
                  tag="div"
                  style="white-space: pre-wrap"
                >
                  <template v-slot:mail>
                    <a :href="`mailto: ${legalData.email}`">
                      {{ legalData.email }}
                    </a>
                  </template>
                  <template v-slot:link>
                    <a
                      href="https://ec.europa.eu/consumers/odr/"
                      target="_blank"
                      >Online Dispute Resolution | European Commission</a
                    >
                  </template>
                  <template v-slot:company-name>
                    {{ legalData.reason }}
                  </template>
                  <template v-slot:cif>
                    {{ legalData.nif_cif }}
                  </template>
                  <template v-slot:address>
                    {{ legalData.address }}
                  </template>
                </i18n>
              </v-card-text>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import { settingCtrl } from "@/controllers";

export default {
  name: "LegalSection",

  provide: {
    heading: { align: "center" },
  },

  data() {
    return {
      loading: true,
      legalData: null,
    };
  },

  async mounted() {
    const legalData = await settingCtrl.getLegalData();
    this.legalData = legalData;
    this.loading = false;
  },

  computed: {
    mail() {
      return '<a mailto="info@foodeo.es">info@foodeo.es</a>';
    },
    description() {
      return this.$route.meta.description;
    },
  },
};
</script>
